import { SIMILAR_JOBS_SCHEMA, JOB_SCHEMA } from "../Schemas/Jobs.schema";

const { gql } = require("@apollo/client");

const GET_JOBS = gql`
  query GetJobs($filterObject: Filters) {
    jobs(filterObject: $filterObject) {
      dataList {
        id
        _id
        activated_date
        company {
          company_partnership_info
          display_name
          logo
          name
          score
          slug
          banner_image
        }
        company_id
        createdAt
        display_name
        external_job_link
        jobStatus
        job_type
        location_type
        max_experience
        min_experience
        min_ctc
        max_ctc
        slug_v2
        tech_stack
        title
        unmapped_company
        unmapped_location
        category_tags {
          _id
          display_name
        }
        location {
          id
          label
          country
          locality
          area
          lat
          lng
        }
      }
      count
      firstJobDetails ${JOB_SCHEMA}
    }
  }
`;
// firstJobDetails ${JOB_SCHEMA}

const GET_JOB_BY_SLUG = gql`
  query GetJobBySlug($slug: String!) {
    job(slug: $slug) {
      id
      _id
      company_id
      company {
        id
        _id
        name
        display_name
        logo
        description
        slug
        company_partnership_info
        about
      }
      unmapped_company
      display_name
      title
      min_experience
      max_experience
      text_body
      unmapped_location
      location_type
      job_role
      min_ctc
      max_ctc
      job_type
      tech_stack
      slug_v2
      external_job_link
      jobStatus
      activated_date
      ai_content {
        must_have
        good_to_have
        perks
        summary
      }
      createdAt

      similar_jobs ${SIMILAR_JOBS_SCHEMA}

      similar_location_jobs ${SIMILAR_JOBS_SCHEMA}

      similar_skills_jobs ${SIMILAR_JOBS_SCHEMA}

      similar_category_jobs ${SIMILAR_JOBS_SCHEMA}

      company_jobs {
        active_jobs
        jobs {
          id
          _id
          activated_date
          slug_v2
          location {
            label
            country
            area
            locality
          }
          location_type
          external_job_link
          title
        }
      }

      location {
        country
        locality
        area
        createdAt
        label
        lat
        lng
        short_country_name
        updatedAt
        id
      }

      category_tags {
        _id
        display_name
      }


      shortlistedBy {
        profile_picture
        fullname
        linkedin
        about
      }
  
      shortlistedCount
    }
  }
`;

export { GET_JOBS, GET_JOB_BY_SLUG };
