const LocalizationMap = {
  in: "en-IN",
  us: "en-US",
  // gb: "en-GB",
  // ca: "en-CA",
  // au: "en-AU",
  // de: "de-DE",
  // fr: "fr-FR",
};
export { LocalizationMap };
