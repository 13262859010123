const SIMILAR_JOBS_SCHEMA = `
  {
    title
    company_id {
      display_name
      logo
      slug
    }
    activated_date
    location {
      label
      country
      locality
      area
    }
    location_type
    external_job_link
    id
    _id
    slug_v2
  }
`;

const JOB_SCHEMA = `
{
    id
    _id
    company_id
    company {
      id
      _id
      name
      display_name
      logo
      description
      slug
      company_partnership_info
      about
    }
    unmapped_company
    display_name
    title
    min_experience
    max_experience
    text_body
    unmapped_location
    location_type
    job_role
    job_type
    tech_stack
    slug_v2
    external_job_link
    jobStatus
    activated_date
    ai_content {
      must_have
      good_to_have
      perks
      summary
    }
    createdAt

    similar_jobs ${SIMILAR_JOBS_SCHEMA}

    similar_location_jobs ${SIMILAR_JOBS_SCHEMA}

    similar_skills_jobs ${SIMILAR_JOBS_SCHEMA}

    similar_category_jobs ${SIMILAR_JOBS_SCHEMA}

    shortlistedBy {
      profile_picture
      fullname
      linkedin
      about
    }

    shortlistedCount

    company_jobs {
      active_jobs
      jobs {
        id
        _id
        activated_date
        slug_v2
        location {
          label
          country
          area
          locality
        }
        location_type
        external_job_link
        title
      }
    }

    location {
      country
      locality
      area
      createdAt
      label
      lat
      lng
      short_country_name
      updatedAt
      id
    }

    category_tags {
      _id
      display_name
    }
  }
`;

export { SIMILAR_JOBS_SCHEMA, JOB_SCHEMA };
