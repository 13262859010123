import axios from "axios";
import globals from "../../Globals";
const USER_DATA_KEY = "userData";

class ServerSideNetworkManager {
  constructor() {
    this.pendingRequests = {};
    this.instance = axios.create({
      baseURL: globals.apiUrl,
    });
  }

  setupDebounce = () => {
    this.instance.interceptors.request.use((config) => {
      const requestKey = `${config.method}${config.url}`;
      if (this.pendingRequests[requestKey]) {
        return this.pendingRequests[requestKey];
      }

      axios(config).finally(() => {
        delete this.pendingRequests[requestKey];
      });

      this.pendingRequests[requestKey] = config;
      return config;
    });
  };

  get = async (api, params = "", query = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        let queryString = "";
        if (Object.keys(query).length > 0) {
          queryString = "?";
        }
        Object.keys(query).map((key) => {
          if (queryString === "?") {
            queryString += key;
          } else {
            queryString += "&" + key;
          }
          queryString = queryString + "=" + query[key];
        });
        const resp = await this.instance.get(api + params + queryString, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        resolve(resp);
      } catch (error) {
        resolve({ statusCode: error.response?.status });
        console.log(error.stack);
      }
    });
  };

  delete = async (api, params = "", query = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        let queryString = "";
        if (Object.keys(query).length > 0) {
          queryString = "?";
        }
        Object.keys(query).map((key) => {
          if (queryString == "?") {
            queryString += "&";
          }
          queryString += key;
          queryString = queryString + "=" + query[key];
        });
        const resp = await this.instance.delete(api + params + queryString, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        resolve(resp);
      } catch (error) {
        resolve({ statusCode: error?.response?.status });
        console.log(error);
      }
    });
  };

  post = async (api, body, params = "", headers = null) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await this.instance.post(api + params, body, {
          headers: headers || {
            "Content-Type": "application/json",
          },
        });
        resolve({ resp });
      } catch (error) {
        resolve({ statusCode: error?.response?.status });
        console.log(error);
      }
    });
  };

  put = async (
    api,
    body,
    params = "",
    headers = {
      "Content-Type": "application/json",
    }
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await this.instance.put(api + params, body, {
          headers,
        });
        resolve(resp);
      } catch (error) {
        resolve({ statusCode: error?.response?.status });
        console.log(error);
      }
    });
  };
}

export default new ServerSideNetworkManager();
