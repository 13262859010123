import Constant from "@/staticData/constant";
import { GET_JOBS } from "../GraphQL/Queries/Jobs.query";

export const specialComponentsData = {
  "section.assessment": {
    apiUrl: "/pages/assessments",
    payload: {},
  },
  "section.statistics": {
    apiUrl: "/pages/statistics-data",
    payload: {},
  },
  "section.latest-jobs": {
    apiUrl: "/pages/jobs?skip=0&limit=4",
    payload: {},
  },
  "section.jobs": {
    query: GET_JOBS,
    payload: {
      filterObject: {
        skip: 0,
        limit: Constant.PAGE_LIMIT,
      },
    },
  },
  "section.pc-auto": {
    sanitizeListData: true,
  },
  "section.courses-listing": {
    tableName: "generic-card-lists",
    payload: {
      filter: "",
      query: "populate[image][fields][0]=url&populate[skills][fields][0]=display_name",
    },
  },
};
