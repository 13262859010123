import ApolloClientManager from "@/Logic/GraphQL/ApolloClientManager";
import StrapiService from "@/Services/Strapi/Strapi.service";
import { ServerSideNetworkManager } from "@/outscal-commons-frontend/Managers";

class SpecialSectionsLogicController {
  constructor(specialComponentsData) {
    this.specialComponents = specialComponentsData;
    this.apolloClient = ApolloClientManager.client;
    this.handlers = {
      apiUrl: (url, payload) => this.#apiHandler(url, payload),
      query: (query, payload) => this.#graphQLHandler(query, payload),
      tableName: (tableName, payload) =>
        this.#strapiTableDataHandler(tableName, payload),
    };
  }

  async populateSpecialSectionsData(pageData) {
    try {
      const pageDataWithAllSections = { ...pageData };
      const sections = pageDataWithAllSections?.sections || [];

      for (let i = 0; i < sections?.length; i++) {
        const section = sections[i];
        const componentConfig = this.specialComponents[section.__component];

        const apiUrl = section?.apiUrl || componentConfig?.apiUrl;
        const tableName = section?.tableName || componentConfig?.tableName;
        const query = section?.query || componentConfig?.query;
        const payload = section?.payload || componentConfig?.payload;

        const prop = apiUrl
          ? "apiUrl"
          : query
          ? "query"
          : tableName
          ? "tableName"
          : null;

        let data = {};

        try {
          data =
            (await this.handlers[prop]?.(
              apiUrl || query || tableName,
              payload
            )) || {};
        } catch (apiError) {
          console.error(
            `Error fetching data for ${section.__component}:`,
            apiError
          );
        }

        if (section?.__component === "section.card-popup-b") {
          data = this.#mapJobsDataToJobsPopupCard(data);
        }

        sections[i] = {
          ...section,
          data: data,
        };
      }

      pageDataWithAllSections.sections = sections;
      return pageDataWithAllSections;
    } catch (error) {
      console.error("Error fetching page data:", error);
      return null;
    }
  }

  #mapJobsDataToJobsPopupCard(data) {
    if (data && data?.dataList) {
      let modifiedDataList = data.dataList.map((item) => ({
        image: { url: item.company.logo },
        heading: item?.title,
        description: `${item?.company.name} | ${item?.location?.label}`,
        ctaLink: `/jobs?job=${item?.slug_v2}`,
      }));
      return { ...data, dataList: modifiedDataList };
    }

    return data;
  }

  async #apiHandler(apiUrl, payload) {
    const response = await ServerSideNetworkManager.post(apiUrl, payload);
    return response?.resp?.data?.data || {};
  }

  async #graphQLHandler(query, payload) {
    const response = await this.apolloClient.query({
      query,
      variables: payload,
    });
    return response?.data?.jobs || {};
  }

  async #strapiTableDataHandler(tableName = "", payload = {}) {
    if (!tableName) {
      return null;
    }

    const { filter = "", query = "" } = payload || {};

    try {
      const tableData = await StrapiService.get(tableName, filter, query, true);
      return tableData;
    } catch (error) {
      console.error("Error returning table data:", error);
      return null;
    }
  }
}

export default SpecialSectionsLogicController;
